// Antd
import { Spin } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
// Styles
import stylesheet from './Loader.module.scss';

export function Loader({ size = 48 }: { size?: number }) {
  return (
    <div className={stylesheet.root}>
      <Spin indicator={(
        <LoadingOutlined style={{ fontSize: size }} spin />
      )} />
    </div>
  );
}

import { Suspense, useContext, useEffect, useState } from 'react';
import { Route, Routes, useNavigate } from 'react-router-dom';
// Components
import { Loader } from 'components/Loader';
// HOC - Hight Order Components
import { AuthContext } from 'common/AuthProvider';
import { ProtectedRoute } from 'common/ProtectedRoute';
// API
import { axios } from 'api';
import axiosRetry from 'axios-retry';
// Containers
import {
  EmptyPage,
  ErrorPage,
  Login,

  // Lazy loaded
  Projects,
  SequenceView,
  GroupView,
  Groups,
  ClusterView,
  StructureView,
  CompareView,
  MSAView,
} from 'containers';
// Types
import { AxiosError } from 'axios';
// Context
import { ConfigContextProvider } from './contexts/ConfigContext';

function App(): JSX.Element {
  const navigate = useNavigate();

  const { user } = useContext(AuthContext);

  const [isConnection, setConnection] = useState(true);
  const [retryCount, setRetryCount] = useState(0);
  const [isPreloading, setIsPreloading] = useState(true);

  const navigateToLoginPage = () => {
    navigate('/login', {
      replace: true,
      state: { from: undefined, showUpdatePasswordForm: false },
    });
  };

  useEffect(() => {
    document.title = 'Proteineer Gene Store';

    axiosRetry(axios, {
      retries: 10,
      retryDelay: (retryCount: number) => {
        setRetryCount(retryCount);
        setConnection(false);

        return retryCount * 5000; // time interval between retries
      },
      retryCondition: (error: AxiosError) => {
        setIsPreloading(false);
        // Redirect to lign if user unauthorized
        if (error?.response?.status === 401) navigateToLoginPage();

        // if retry condition is not specified, by default idempotent requests are retried
        return error?.response?.status === 500;
      },
    });
  }, []);

  useEffect(() => {
    if (user) {
      // Preload lazy-loaded components after login
      const preloadComponents = async () => {
        setIsPreloading(true);

        await Promise.all([
          Projects.preload(),
          Groups.preload(),
          GroupView.preload(),
          SequenceView.preload(),
          ClusterView.preload(),
          StructureView.preload(),
          CompareView.preload(),
          MSAView.preload(),
        ]);

        setIsPreloading(false);
      };

      void preloadComponents();
    }
  }, [user]);

  return (
    <Suspense>
      <ConfigContextProvider>
        {isPreloading
          ? (
            <Loader /> // Show a loader while preloading components
          )
          : (
            <Routes>
              <Route path="/login" element={<Login />} />
              {isConnection
                ? (
                  <Route element={<ProtectedRoute />}>
                    <Route path="/" index element={<Projects />} />
                    <Route path="/projects/:projectId/groups" element={<Groups />} />
                    <Route path="/projects/:projectId/groups/:groupId" element={<GroupView />} />
                    <Route path="/projects/:projectId/groups/:groupId/sequences" element={<SequenceView />} />
                    <Route path="/projects/:projectId/groups/:groupId/sequences/msa" element={<MSAView />} />
                    <Route path="/projects/:projectId/groups/:groupId/clusters/:clusterId/msa" element={<MSAView />} />
                    <Route path="/projects/:projectId/groups/:groupId/sequences/structure" element={<StructureView />} />
                    <Route path="/projects/:projectId/groups/:groupId/compare" element={<CompareView />} />
                    <Route path="/projects/:projectId/groups/:groupId/sequences/compare" element={<CompareView />} />
                    <Route path="/projects/:projectId/groups/:groupId/clusters/:clusterId" element={<ClusterView />} />
                    <Route path="/projects/:projectId/groups/:groupId/clusters/:clusterId/compare" element={<CompareView />} />
                    <Route path="*" index element={<EmptyPage />} />
                  </Route>
                )
                : (
                  <Route
                    path="*"
                    index
                    element={<ErrorPage setConnection={setConnection} retryCount={retryCount} />}
                  />
                )
              }
            </Routes>
          )
        }
      </ConfigContextProvider>
    </Suspense>
  );
}

export default App;

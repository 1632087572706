import { createContext, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
// Libs
import _ from 'lodash';
// Antd
import { notification } from 'antd';
// API
import { sequenceApi } from 'api';
// Context
import { AuthContext } from 'common';
// Types
import { Config, Ligand, Project, SequenceGroupData, SequenceTag, Tag } from 'types';
import { ColumnFilter } from 'components/SequenceTable/types';
import { AxiosError } from 'axios';

interface ConfigContextType {
  config: Config;
  ligands: Ligand[];
  refreshLigands: () => void;
  tags: Tag[];
  tagLabels: Record<string, SequenceTag[]>;
  refreshTags: () => void;
  sequenceGroupSource: (string | number)[];
  filter: Record<string, ColumnFilter>;
  setFilter: (filter: Record<string, ColumnFilter>) => void;
  projects: Project[];
  projectGroups: SequenceGroupData;
  setProjectGroups: (
    projectGroups: SequenceGroupData | ((prevState: SequenceGroupData) => SequenceGroupData)
  ) => void;
  projectGroupsLoading: boolean;
}

const ConfigContext = createContext<ConfigContextType>({
  config: {},
  ligands: [],
  refreshLigands: () => {},
  tags: [],
  tagLabels: {},
  refreshTags: () => {},
  sequenceGroupSource: [],
  filter: {},
  setFilter: () => {},
  projects: [],
  projectGroups: [],
  setProjectGroups: () => {},
  projectGroupsLoading: true,
});

const ConfigContextProvider = ({ children }: any) => {
  const location = useLocation();

  const { user } = useContext(AuthContext);
  /*
   * projectId
   */
  const projectId = useMemo(() => {
    // This effect will run whenever the URL changes
    const urlSegments = location.pathname.split('/');
    // Find the index of the 'projects' segment
    const projectsIndex = urlSegments.indexOf('projects');
    // Extract the ID following the 'projects' segment
    return urlSegments[projectsIndex + 1];
  }, [location.pathname]);

  /*
   * config
   */
  const [config, setConfig] = useState<Config>({});
  const [projectGroups, setProjectGroups] = useState<SequenceGroupData>([]);
  const [projectGroupsLoading, setProjectGroupsLoading] = useState(true);
  const [projects, setProjects] = useState<Project[]>([]);

  const fetchProjectConfig = () => {
    if (!projectId) {
      setConfig({});
      return;
    }
    sequenceApi.getProjectConfig({ projectId })
      .then(data => {
        setConfig(data);
      })
      .catch((error: AxiosError) => {
        setConfig({});
        notification.error({ message: `Get project config error:', ${error.message}` });
      });
  };

  const fetchProjectGroups = () => {
    setProjectGroups([]);
    sequenceApi.getSequenceGroups({ projectId })
      .then(seqGroups => {
        setProjectGroups(seqGroups);
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get sequence groups error: ${error.message}` });
      })
      .finally(() => {
        setProjectGroupsLoading(false);
      });
  };

  const fetchListOfProjects = () => {
    sequenceApi.getUserProjects()
      .then(projects => {
        setProjects(projects);
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get user projects failed: ${error.message}`, closeIcon: true });
      });
  };

  useEffect(() => {
    if (projectId && user) {
      fetchProjectConfig();
    }
  }, [projectId, user]);

  useEffect(() => {
    if (projectId) {
      fetchProjectGroups();
    } else {
      setProjectGroupsLoading(true);
    }
  }, [projectId, user]);

  useEffect(() => {
    if (user) {
      fetchListOfProjects();
    }
  }, [user]);
  /*
   * ligands
   */
  const [ligands, setLigands] = useState<Ligand[]>([]);

  const refreshLigands = () => {
    if (!projectId || !config.frontendConfig?.ligandDocking) {
      setLigands([]);
      return;
    }
    sequenceApi.getProjectLigands({ projectId })
      .then(data => {
        setLigands(data);
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get project ligends error: ${error.message}` });
      });
  };

  useEffect(() => {
    refreshLigands();
  }, [projectId, config, user]);

  /*
   * tags
   */
  const [tags, setTags] = useState<Tag[]>([]);
  const [tagLabels, setTagLabels] = useState<Record<string, SequenceTag[]>>({});

  const refreshTags = () => {
    if (!projectId || !config.frontendConfig?.tags) {
      setTags([]);
      setTagLabels({});
      return;
    }
    sequenceApi.getProjectTags({ projectId })
      .then(projectTags => {
        setTags(projectTags);
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get project tags error: ${error.message}` });
      });
  };

  useEffect(() => {
    refreshTags();
  }, [projectId, config, user]);

  useEffect(() => {
    // TODO: Remove all tagLabels that have no tag.
    if (!projectId || !config.frontendConfig?.tags) {
      setTagLabels({});
      return;
    }
    _.keys(tagLabels).forEach(tagId => {
      if (!tags.find(tag => tag.uuid === tagId)) {
        setTagLabels(prevState => _.omit(prevState, tagId));
      }
    });
    tags.forEach(tag => {
      sequenceApi.getTagLabels({ projectId, tagId: tag.uuid }).then(data => {
        setTagLabels(prevState => ({ ...prevState, [tag.uuid]: data }));
      }).catch(() => {
        setTagLabels(prevState => ({ ...prevState, [tag.uuid]: [] }));
      });
    });
  }, [projectId, config, tags, user]);

  /*
   * groupId
   */
  const groupId = useMemo(() => {
    const urlSegments = location.pathname.split('/');
    const groupsIndex = urlSegments.indexOf('groups');
    if (groupsIndex === -1 || groupsIndex === urlSegments.length - 1) {
      return undefined;
    }
    const n = parseInt(urlSegments[groupsIndex + 1]);
    return isNaN(n) ? undefined : n;
  }, [location.pathname]);

  /*
   * sequenceGroupSource
   */
  const [sequenceGroupSource, setSequenceGroupSource] = useState<(string | number)[]>([]);

  useEffect(() => {
    if (!projectId || !groupId) {
      setSequenceGroupSource([]);
      return;
    }
    sequenceApi.getSequenceGroupSource({ projectId, groupId })
      .then(data => {
        setSequenceGroupSource(data);
      })
      .catch((error: AxiosError) => {
        notification.error({ message: `Get sequences group source error: ${error.message}` });
      });
  }, [projectId, groupId]);

  /*
   * filter
   */
  const [filter, setFilter] = useState<Record<string, ColumnFilter>>({});

  useEffect(() => {
    // Reset filter on groupId change.
    setFilter({});
  }, [groupId]);

  return (
    <ConfigContext.Provider value={{
      config,
      ligands,
      refreshLigands,
      tags,
      tagLabels,
      refreshTags,
      sequenceGroupSource,
      filter,
      setFilter,
      projects,
      projectGroups,
      setProjectGroups,
      projectGroupsLoading,
    }}>
      {children}
    </ConfigContext.Provider>
  );
};

export { ConfigContext, ConfigContextProvider };

import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import { authApi, userApi } from 'api';
import { LoginPayload, UpdatePasswordPayload, User } from 'types';

export const AuthContext = React.createContext<{
  initLoading?: boolean;
  user?: User;
  login?: (payload: LoginPayload) => Promise<User>;
  logout?: () => void;
  updatePassword?: (payload: UpdatePasswordPayload) => Promise<void>;
    }>({});

export function AuthProvider({ children }: { children: React.ReactNode }): JSX.Element {
  const navigate = useNavigate();
  const location = useLocation();

  const [user, setUser] = useState<User | undefined>();
  const [initLoading, setInitLoading] = useState(true);

  const navigateToLoginPage = (showUpdatePasswordForm = false) => {
    const from = location.pathname !== '/login' ? location : undefined;

    navigate('/login', {
      replace: true,
      state: { from, showUpdatePasswordForm },
    });
  };

  const login = async (payload: LoginPayload): Promise<User> => {
    const user = await authApi.login(payload);
    setUser(user);

    return user;
  };

  const updatePassword = async ({ password }: { password: string }) => {
    await authApi.updatePassword({ password });
  };

  const logout = () => {
    authApi.logout()
      .then(() => {
        setUser(undefined);
        navigateToLoginPage();
      })
      .catch((error) => {
        console.error('Logout failed:', error);
        setUser(undefined);
      });
  };

  useEffect(() => {
    userApi.getUser()
      .then((user) => {
        setInitLoading(false);
        setUser(user);

        if (!user.passwordUpdated) {
          navigateToLoginPage(true);
        }
      })
      .catch(() => {
        setInitLoading(false);
        navigateToLoginPage();
      });
  }, []);

  return (
    <AuthContext.Provider value={{
      initLoading,
      user,
      login,
      logout,
      updatePassword,
    }}>
      {children}
    </AuthContext.Provider>
  );
}
